import Vue from 'vue'
import { filterTransitions, getStateConfigs } from '../../scripts/configs/patient-state-machine'
import i18n from '@/scripts/app-configs/i18n-config'
import prescription from './patient/patientPrescriptionStore'
import patientChatPopup from './patient/patientChatPopupStore'
import { shDate } from '@/utils/filters'
import { PATIENT_UNIT } from '@/scripts/configs/patient-units'
import { prefillZendesk } from '@/scripts/integrations/zendesk'

// Utils
import { triggers } from '@/scripts/global-modals-commands'
import { camelToSnakeCase } from '@/utils/filters'
import { lokiPhase } from '@/views/ai-feed/utils/loki-formatter'

const makeSetStatePayload = (
  {
    current_state: currentState,
    remaining_days_in_on_hold: remainingDaysInOnHold = null,
    possible_transitions: possibleTransitions,
    has_pending_exclusion_request: hasPendingExclusionRequests,
    start_date_on_hold: startDateOnHold = null,
    schedule_for_n_days: scheduleDaysOnHold = null,
  },
  memberUnit,
) => {
  if (!currentState) return {}

  const currentStateHandler = currentState.patient_state.current_state

  return {
    currentState: currentState.patient_state,
    lastTransition: currentState.last_transition,
    possibleTransitions: filterTransitions(currentStateHandler, possibleTransitions, memberUnit),
    stateDecoded: getStateConfigs(currentStateHandler),
    hasPendingExclusionRequests,
    remainingDaysInOnHold,
    startDateOnHold,
    scheduleDaysOnHold,
  }
}

const convertProgramToCurrentState = (program) => {
  if (!program) {
    return null
  }

  return {
    patient_state: {
      current_state: program.state,
      patient_uuid: program.program_uuid,
      environment: program.environment,
      member_account_uuid: program.account_uuid,
      institution_uuid: program.institution_uuid,
      unit: program.unit,
    },
    last_transition: program.transitions?.length ? program.transitions[program.transitions.length - 1] : null,
  }
}

const defaultPatientStateStructure = {
  root() {
    return {
      __local: {
        fetching: {
          profile: null,
          resume: null,
          state: null,
          files: null,
          referrals: null,
          ecosystemReferrals: null,
          ecosystemServices: null,
        },
        professionalBeingDeleted: [],
        activeTherapists: {},
        stateProfessionalByInstitutionId: {},
      },
      user_id: null,
      uuid: null,
      member_account_id: null,
      member_account: null,
      gutenberg_flag: null,
      key: null, // TODO review if to deprecate
      archived: null,
      origin: null, // Platform that triggered patient creation
      institution_id: null, // Only come with get patient resume service
      confirmationCode: null,
      goals: [],
      notes: [], // Only come with get patient resume service
      demographics: {},
      onboardingConfigs: { // Stores onboarding version configs
        key: null,
        alias: null,
        has_problematic_side: false,
      },
      serviceType: null,
      unit: null,
      client: {},
      has_pending_prescription: null,
      program_focus: null,
      has_loki_adjusted_sessions: null,
      is_loki_enabled: null,
      is_loki_phase_two_enabled: null,
    }
  },
  general() {
    return {
      firstname: '',
      lastname: '',
      name: '',
      pronoun: '',
      race: '',
      timezone: '',
      is_vip_account: false,
      patient_is_high_risk: false,
      education_level: '',
      employment_status: '',
      current_job: '',
      picture: undefined,
      birthdate: undefined,
      gender: undefined,
      telephone: null,
      phone: {},
      email: '',
      health_plan_info: {},
      guardian: {
        firstname: '',
        lastname: '',
        email: '',
      },
      requiredPTMemberRelationship: false,
    }
  },
  professionals() {
    return {
      owner_id: '',
      intake_pt_id: '',
      shared: [],
    }
  },
  card() {
    return {
      card_number: null,
      cvv: null,
      id: null,
      card_uuid: null, // Using card_ prefix so this doesn't collide with patient uuid
      // when dynamic matching by property names
    }
  },
  metadata() {
    return {
      address_line_1: null,
      address_line_2: null,
      zip_code: null,
      country: null,
      city: null,
      is_company_address: null,
      geographical_state: null,
      wifi_at_home: null,
      company_name: null,
      is_multimode: false,
    }
  },
  preferences() {
    return {
      language: '',
      measurement_system: '',
    }
  },
  clinical() {
    return {
      apk_version: null,
      bloom_pod_version: null,
      pathology: {},
      other_conditions: [],
      height: null,
      weight: null,
      hie_skip_allowed: 0,
      other_info: '',
      surgeryDate: undefined,
      problematic_side: null,
      kit: '',
      kit_status: null,
      clinical_information: {
        exclusions: [],
        outcomes: [],
      },
      referrals: [],
      medical_history: {},
      allow_only_one_session: true,
      skipped_video_call: 0,
      sword_go_enabled: true,
    }
  },
  careCoordinator() {
    return {
      profile: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      },
      additionalQuestions: {
        carePathways: undefined,
        surgicalProcedureNotes: undefined,
      },
    }
  },
  counters() {
    return {
      count_results: null,
      count_sessions_with_adherence: null,
      first_training_date: null,
      total_prescriptions: null,
      total_recommendations: null,
      weekly_frequency: null,
      adherence_commitment: null,
      count_total_sessions_non_default: null,
    }
  },
  metrics() {
    return {
      adherence: null,
      performance: null,
      satisfaction: null,
    }
  },
  state() {
    return {
      currentState: {},
      lastTransition: {},
      possibleTransitions: [],
      stateDecoded: {},
    }
  },
  moveHeader() {
    return {
      goals: {
        motivation: [],
        personal_goal: null,
      },
      fitness_level: null,
      availability: [],
      workout_time: null,
      wearable: null,
    }
  },
  careEcosystem() {
    return {
      referrals: [],
      services: [],
    }
  },
}

const generateBaseStructure = () => {
  const stateArray = Object.entries(defaultPatientStateStructure).map(([groupName, groupData]) => {
    return { [groupName]: groupData() }
  })
  const stateObj = Object.assign({}, ...stateArray, defaultPatientStateStructure.root())

  delete stateObj.root

  return stateObj
}

export default {
  namespaced: true,
  modules: {
    prescription,
    patientChatPopup,
  },
  state: generateBaseStructure(),
  getters: {
    isLoaded: (state) => {
      const profileFetchState = state.__local.fetching.profile
      const stateFetchState = state.__local.fetching.state
      const profileReady = profileFetchState !== true && profileFetchState !== null
      const stateReady = stateFetchState !== true && stateFetchState !== null

      return {
        fully: profileReady && stateReady,
        profile: profileReady,
        state: stateReady,
      }
    },
    isFetching: (state) => (key) => state.__local.fetching[key],
    isReady: (state) => {
      return state.user_id
    },
    getOnboardingConfig: (state) => (feature) => {
      if (!state.origin || state.origin === 'portal') {
        return null
      }

      return feature ? state.onboardingConfigs[feature] : state.onboardingConfigs
    },
    getFullMemberProfile: (state) => state,
    getMemberBasics: (state) => ({
      accountUuid: state.member_account?.account?.account_id,
      accountUuidAux: state.member_account_id,
      birthdate: state.general.birthdate,
      educationLevel: state.general.education_level,
      email: state.general.email,
      firstname: state.general.firstname,
      fullName:  `${state.general.firstname} ${state.general.lastname}`,
      gender: state.general.gender,
      id: state.user_id,
      institutionId: state.institution_id,
      isHighRisk:  !!state.general.patient_is_high_risk,
      isVip:  !!state.general.is_vip_account,
      lastname: state.general.lastname,
      name: state.general.name,
      phone: state.general.phone,
      picture: state.general.picture,
      preferredName: state.member_account?.account?.preferred_name,
      pronoun: state.general.pronoun,
      race: state.general.race,
      serviceType: state.serviceType,
      telephone: state.general.telephone,
      timezone: state.general.timezone,
      unit: state.unit,
      uuid: state.uuid,
      key: state.key, // TODO review if to deprecate
      confirmationCode: state.confirmationCode,
      ownerId: state.professionals.owner_id,
      intakePtId: (state) => state.professionals.intake_pt_id,
      origin: state.origin,
      programFocus: state.program_focus,
      lokiPhase: lokiPhase(state.is_loki_enabled, state.is_loki_phase_two_enabled),
    }),
    getClinical: (state) => state.clinical,
    getHealthPlan: (state) => state.general.health_plan_info,
    getState: (state) => state.state,
    getCounters: (state) => state.counters,
    getMetrics: (state) => state.metrics,
    getMetadata: (state) => state.metadata,
    getPreferences: (state) => state.preferences,
    getProfessionals: (state) => state.professionals,
    getGoals: (state) => state.goals || [],
    getDemographics: (state) => state.demographics || {},
    getClientData: (state) => state.client || {},
    getCareCoordinator: (state) => state.careCoordinator,
    getFlags: (state) => ({
      hasPendingPrescription: state.has_pending_prescription,
      hasLokiAdjustedSessions: state.has_loki_adjusted_sessions,
      isGutenbergMember: state.gutenberg_flag,
      requiredPTMemberRelationship: state.general.requiredPTMemberRelationship,
      isOnboarding20: state.onboardingConfigs && state.onboardingConfigs.key === 'onboarding_2_0',
    }),
    getMoveHeaderData: (state) => state.moveHeader || {},
    getPatientCardInfo: (state) => {
      const cardDefined = state.card && state.card.card_number && state.card.cvv

      return cardDefined ? state.card : null
    },
    getSharedProfessionals: (state) => state.professionals.shared,
    getProfessionalsBeingDeleted: (state) => state.__local.professionalBeingDeleted,
    getGuardian: (state) => state.general.guardian,
    getClinicalReferrals: (state) => state.clinical.referrals,
    getEcosystemReferrals: (state) => state.careEcosystem.referrals,
    getEcosystemServices: (state) => state.careEcosystem.services,
    isPatientUnderage: (state) => {
      if (!state.general || !state.general.birthdate) {
        return false
      }

      const age = shDate(state.general.birthdate, 'age', { input: 'YYYY-MM-DD' })

      return age && age !== 0 && age < 18
    },
    isEnabledClinicalHistory: (state, getters) => {
      const { country: patientCountry } = getters.getMetadata

      return patientCountry && patientCountry.toUpperCase() === 'US'
    },
    canGetPTMessage: (state, getters) => {
      const hasInitialMessage = getters['prescription/getInitialMessage']
      const hasRequiredPTMemberRelationship = state.general.requiredPTMemberRelationship
      const hasSkippedVideoCall = state.clinical.skipped_video_call

      return !hasInitialMessage && hasRequiredPTMemberRelationship && hasSkippedVideoCall
    },
    getCurrentInstitutionStateProfessionals: (state, getters, rootState, rootGetters) => {
      const institutionId = rootGetters['user/currentInstitutionId']

      return institutionId ? state.__local.stateProfessionalByInstitutionId[institutionId] : undefined
    },
    getActiveTherapists: (state) => state.__local.activeTherapists,
  },
  mutations: {
    setAsFetching(state, payload) {
      Object.keys(payload).forEach((prop) => {
        state.__local.fetching[prop] = payload[prop]
      })
    },
    resetPatient(state) {
      Object.assign(state, generateBaseStructure())
    },
    setProfessionalAsBeingRemoved(state, professionalId) {
      state.__local.professionalBeingDeleted.push(professionalId)
    },
    removeProfessionalAsBeingRemoved(state, professionalId) {
      const index = state.__local.professionalBeingDeleted.indexOf(professionalId)

      state.__local.professionalBeingDeleted.splice(index, 1)
    },
    setOnboardingConfigs(state, configs) {
      Object.keys(configs).forEach((prop) => {
        state.onboardingConfigs[prop] = configs[prop]
      })
    },
    setPatientRootContext: (state, payload) => {

      const rootProps = defaultPatientStateStructure.root()

      Object.keys(rootProps).forEach((prop) => {
        const newValue = payload[prop]

        if (newValue !== undefined) {
          state[prop] = newValue
        }
      })

    },
    setPatientContext: (state, payload) => {

      Object.entries(payload).forEach(([contextName, contextPayload]) => {
        Object.keys(state[contextName]).forEach((prop) => {

          // TODO PROTECTING FUCKED UP PROFESSIONALS
          if (!contextPayload) {
            return
          }
          const newValue = contextPayload && contextPayload[prop]

          if (newValue !== undefined) {
            state[contextName][prop] = newValue
          }
        })
      })
    },
    setConfirmationCode: (state, code) => {
      state.confirmationCode = code
    },
    setPatientCard: (state, { id, card_uuid: cardUuid, card_number: cardNumber, cvv } = {}) => {
      if (!cardUuid || !cardNumber || !cvv) {
        return
      }
      state.card.id = id
      state.card.card_number = cardNumber
      state.card.cvv = cvv
      state.card.card_uuid = cardUuid
    },
    setPatientNotes: (state, notes) => {
      state.notes = notes
    },
    setGuardianInfo: (state, onboarding) => {
      if (!onboarding) {
        return
      }
      state.general.guardian.firstname = onboarding.first_name
      state.general.guardian.lastname = onboarding.last_name
      state.general.guardian.email = onboarding.email
    },
    setPatientCareCoordinatorProfile: (state, careCoordinatorProfile) => {
      Object.keys(state.careCoordinator.profile).forEach((field) => {
        const newValue = careCoordinatorProfile[camelToSnakeCase(field)]

        if (newValue !== undefined) {
          state.careCoordinator.profile[field] = newValue
        }
      })
    },
    setPatientCareCoordinatorQuestions: (state, careCoordinatorQuestions) => {
      Object.keys(state.careCoordinator.additionalQuestions).forEach((question) => {
        const newValue = careCoordinatorQuestions[camelToSnakeCase(question)]

        if (newValue !== undefined) {
          state.careCoordinator.additionalQuestions[question] = newValue
        }
      })
    },
    setPatientServiceType: (state, serviceType) => {
      state.serviceType = serviceType
    },
    updatePatient: (state, { section, payload }) => {
      Object.keys(payload).forEach((prop) => {
        const newValue = payload[prop]

        // Root state properties
        if (!section) {
          state[prop] = newValue

          return
        }
        state[section][prop] = newValue
      })
    },
    updateCounters: (state, payload) => {
      // eslint-disable-next-line array-callback-return
      Object.entries(payload).forEach(([counter, newValue]) => {
        if (!(counter in state.counters)) {
          console.warn(`[patient-store] - Unable to find counter '${counter}'`)

          return
        }
        switch (newValue) {
          case '++':
            state.counters[counter]++
            break
          case '--':
            state.counters[counter]--
            break
          default:
            state.counters[counter] = newValue
            break
        }
      })
    },
    setPatientReferrals(state, referrals) {
      state.clinical.referrals = referrals || []
    },
    setPatientEcosystemReferrals(state, referrals) {
      // sort by created at (DESC) and by vendor name (ASC)
      const sortedReferrals = referrals?.sort((a, b) => a.vendor.localeCompare(b.vendor))
        .sort((a, b) => new Date(b.created_at.split(' ')[0]) - new Date(a.created_at.split(' ')[0]))

      state.careEcosystem.referrals = sortedReferrals || []
    },
    setPatientEcosystemServices(state, services) {
      state.careEcosystem.services = services || []
    },
    appendPatientReferral(state, referral) {
      state.clinical.referrals.unshift(referral)
    },
    appendPatientEcosystemReferral(state, referral) {
      state.careEcosystem.referrals.unshift(referral)
    },
    updatePatientReferralByUUID(state, { referralUUID, newReferral }) {
      const referralIndex = state.clinical.referrals.findIndex((r) => r.uuid === referralUUID)

      if (referralIndex !== -1) {
        Vue.set(state.clinical.referrals, referralIndex, newReferral)
      }
    },
    setAllowOnlyOneActiveSession(state, newAllowOnlyOneActiveSession) {
      Vue.set(state.clinical, 'allow_only_one_session', newAllowOnlyOneActiveSession)
    },
    setRequiredPTMemberRelationship(state, requiredPTMemberRelationship = null) {
      state.general.requiredPTMemberRelationship = requiredPTMemberRelationship
    },
    setActiveTherapists(state, therapists) {
      Vue.set(state.__local, 'activeTherapists', therapists)
    },
    setStateRegulatedTherapists(state, { institutionId, stateTherapists }) {
      Vue.set(state.__local.stateProfessionalByInstitutionId, institutionId, stateTherapists)
    },
  },
  actions: {
    discardAllPatientRelatedData({ commit }) {
      commit('resetPatient')
      commit('prescription/protocol/resetProtocolPrescription')
      commit('prescription/resetPrescribedSessions')
      commit('results/resetState', undefined, { root: true })
      triggers.closeNotes()
    },
    invalidatePatientIfDifferentFromStored({ state, dispatch }, newPatientId) {
      if (state.user_id && Number(newPatientId) !== state.user_id) {
        dispatch('discardAllPatientRelatedData')
      }
    },
    async fetchPatientProfile({ commit, dispatch, getters }, patientID) {

      commit('setAsFetching', { profile: true })

      // TODO Remove stuff from this single request ASAP and implement lazy load call for that data
      const includeOnResponse = [
        'professionals',
        'goals',
        'notes',
        'card',
        'demographics',
        'clinical_information',
        'enrollment',
        'member_account',
        'vip_account_id',
        'client',
        'profile_picture',
      ]

      try {
        const { data } = await Vue.$http('patient/profile/getPatient', { patientID, include: includeOnResponse } )

        const newContexts = {
          general: data.general,
          professionals: data.professionals,
          metadata: data.metadata,
          preferences: data.preferences,
          counters: data.counters,
          metrics: data.metrics,
          clinical: data.clinical,
        }

        dispatch('getGeoConfigs', patientID)

        // If getting a different patient from the one that is stored, invalid all stored data
        dispatch('invalidatePatientIfDifferentFromStored', patientID)

        commit('setPatientRootContext', data)
        commit('setPatientContext', newContexts)
        commit('setPatientNotes', data.notes)
        commit('setPatientCard', data.card)
        dispatch('setPatientEnrollmentData', data.enrollment)
        prefillZendesk({
          name: data.unit !== PATIENT_UNIT.BLOOM ? data.general.name : '',
          email: data.unit !== PATIENT_UNIT.BLOOM ? data.general.email : '',
          programID: data.user_id,
          programUUID: data.uuid,
          emailPT: data.professionals.shared?.find(((pt) => pt.user_id === data.professionals.owner_id))?.email,
          unit: data.unit,
        })

        const { unit: memberUnit } = getters.getMemberBasics

        if (memberUnit === PATIENT_UNIT.BLOOM) {
          await dispatch('fetchClinicalPatientMetrics', getters.getMemberBasics.uuid)
        }

        if (memberUnit === PATIENT_UNIT.MOVE) {
          await dispatch('fetchMoveMemberHeaderData', getters.getMemberBasics.uuid)
        }

        commit('setAsFetching', { profile: false })

        return Promise.resolve(getters.getFullMemberProfile)
      } catch (e) {
        commit('setAsFetching', { profile: false })

        return Promise.reject(e)
      }
    },
    setPatientEnrollmentData({ commit }, enrollment = {}) {
      if (!enrollment || !Object.keys(enrollment).length) {
        return
      }

      if (enrollment.care_coordinator?.filled_by === 'care_coordinator') {
        commit('setPatientCareCoordinatorProfile', enrollment.care_coordinator.profile)
        commit('setPatientCareCoordinatorQuestions', enrollment.care_coordinator.additional_patient_questions)
      }
      commit('setPatientServiceType', enrollment.service_type)
      commit('setGuardianInfo', enrollment.guardian)
    },
    async fetchEcosystemVendorsServices({ commit }, { institutionId, locale }) {
      commit('setAsFetching', { ecosystemServices: true })

      return Vue.$http('patient/onboarding/referrals/fetchEcosystemVendorsServices', { institutionId, locale })
        .then(({ data }) => {
          commit('setPatientEcosystemServices', data.entries)
        })
        .catch((e) => { throw e })
        .finally(() => commit('setAsFetching', { ecosystemServices: false }))
    },
    async fetchEcosystemReferrals({ commit }, patientId) {
      commit('setAsFetching', { ecosystemReferrals: true })

      return Vue.$http('patient/onboarding/referrals/fetchEcosystemReferrals', patientId)
        .then(({ data }) => {
          commit('setPatientEcosystemReferrals', data.entries)
        })
        .catch((e) => { throw e })
        .finally(() => commit('setAsFetching', { ecosystemReferrals: false }))
    },
    createEcosystemReferral({ commit }, { patientId, serviceId }) {
      commit('setAsFetching', { ecosystemReferrals: true })
      const payload = {
        'patient_id': patientId,
        'service_id': serviceId,
      }

      return Vue.$http('patient/onboarding/referrals/createEcosystemReferral', patientId, { body: payload })
        .then(({ data }) => {
          commit('appendPatientEcosystemReferral', data)
        })
        .catch((e) => { throw e })
        .finally(() => commit('setAsFetching', { ecosystemReferrals: false }))
    },
    createReferral({ commit }, { patientUUID }) {
      commit('setAsFetching', { referrals: true })
      const payload = {
        'member_uuid': patientUUID,
      }

      return Vue.$http('patient/onboarding/referrals/createReferral', null, { body: payload })
        .then(({ data }) => {
          commit('appendPatientReferral', data)
        })
        .catch((e) => console.error(e))
        .finally(() => commit('setAsFetching', { referrals: false }))
    },
    updatePatientReferral({ commit }, { referralUUID }) {
      commit('setAsFetching', { referrals: true })

      return Vue.$http('patient/onboarding/referrals/updateReferral', referralUUID)
        .then(({ data }) => {
          commit('updatePatientReferralByUUID', { referralUUID, newReferral: data })
        })
        .catch((e) => console.error(e))
        .finally(() => commit('setAsFetching', { referrals: false }))
    },
    resubmitReferral({ commit }, { referralUUID, notes }) {
      commit('setAsFetching', { referrals: true })

      return Vue.$http('patient/onboarding/referrals/resubmitReferral', referralUUID, { body: { notes } })
        .then(({ data }) => {
          commit('appendPatientReferral', data)
        })
        .catch((e) => console.error(e))
        .finally(() => commit('setAsFetching', { referrals: false }))
    },
    async setPatientState({ getters, commit }, { patientId, statePayload }) {
      commit('setAsFetching', { state: true })
      try {
        // Update State on state machine
        const { data } = await Vue.$http('patient/state/setPatientState', { patientId }, { body: statePayload })

        if (data.type && data.type === 'exclusion_request') {
          Vue.$notify.success(i18n.t('CHANGE_STATUS.NOTIFICATION.EXCLUSION_REQUEST_CREATED'))
        } else {
          const { unit: memberUnit } = getters.getMemberBasics

          commit('updatePatient', {
            section: 'state',
            payload: makeSetStatePayload(data, memberUnit),
          })
          Vue.$notify.success(i18n.t('CHANGE_STATUS.NOTIFICATION.SUCCESS'))
        }
      } catch (e) {
        console.error(e)
        Vue.$notify.error(i18n.t('CHANGE_STATUS.NOTIFICATION.ERROR'))
      }
      commit('setAsFetching', { state: false })
    },
    async removeOnHoldState({ getters, commit }) {
      commit('updatePatient', {
        section: 'state',
        payload: { ...getters.getState, startDateOnHold: null },
      })
    },
    async cancelScheduledStateTransition({ dispatch }, patientId) {
      try {
        await Vue.$http('patient/state/cancelScheduledTransition', { patientId })

        dispatch('removeOnHoldState')

        Vue.$notify.success(i18n.t('CHANGE_STATUS.NOTIFICATION.CANCELLED'))
      } catch (e) {
        console.error(e)
        Vue.$notify.error(i18n.t('CHANGE_STATUS.NOTIFICATION.ERROR'))
      }
    },
    async addPatient(_, { formData }) {
      const apiFormattedData = {
        firstname: formData.firstName,
        lastname: formData.lastName,
        birthdate: formData.birthdate,
        gender: formData.gender,
        has_provider_enabled: formData.hasProviderEnabled,
        email: formData.email,
        telephone: formData.telephone,
        prefix: formData.prefix,
        user_metadata: formData.metadata,
        other_conditions: formData.otherConditions,
        other_info: formData.otherInfo,
        condition: formData.condition,
        therapy_name: formData.therapyName,
        side: formData.side,
        language: formData.language,
        measurement_system: formData.measureUnits,
        height: formData.height,
        weight: formData.weight,
        institution_id: formData.institution,
        unit: formData.unit,
        timezone: formData.timezone,
        origin: 'portal',
        kit: formData.kit,
      }

      return Vue.$http('patient/addPatient', null, { body: apiFormattedData })
    },
    async updatePatientField({ commit }, { patientId, field, value, dptActivityUUID }) {
      try {
        await Vue.$http('patient/profile/updateProfile', { patientId }, {
          body: { [field]: value },
          config: {
            'DPT-Activity-UUID': dptActivityUUID,
          },
        })
        commit('updateCounters', { [field]: value })
      } catch (e) {
        console.error('[patient-update] Error updating patient data', e)
      }
    },
    async updatePatientProfile(_, { patientId, formData }) {
      const apiFormattedData = {
        user_id: formData.patientId,
        firstname: formData.firstName,
        lastname: formData.lastName,
        birthdate: formData.birthdate,
        gender: formData.gender,
        email: formData.email,
        has_provider_enabled: formData.hasProviderEnabled,
        telephone: `${formData.prefix}${formData.telephone.replace(formData.prefix, '')}`,
        prefix: formData.prefix,
        user_metadata: formData.metadata,
        other_conditions: formData.otherConditions,
        other_info: formData.otherInfo,
        condition: formData.condition,
        therapy_name: formData.therapyName,
        side: formData.side,
        language: formData.language,
        measurement_system: formData.measureUnits,
        height: formData.height,
        weight: formData.weight,
        kit: formData.kit,
      }

      return Vue.$http('patient/profile/updateProfile', { patientId }, { body: apiFormattedData })
    },
    async updatePatientPersonalInformation(_, { patientId, formData }) {
      const apiFormattedData = {
        member_account_uuid: formData.memberAccountUUID,
        firstname: formData.firstName,
        lastname: formData.lastName,
        preferred_name: formData.preferredName,
        birthdate: formData.birthdate,
        gender: formData.gender,
        email: formData.email,
        telephone: `${formData.telephone.replace(formData.prefix, '')}`,
        prefix: formData.prefix,
        user_metadata: formData.metadata,
        language: formData.language,
        measurement_system: formData.measureUnits,
        timezone: formData.timezone,
      }

      return Vue.$http('patient/profile/updatePatientPersonalInformation', { patientId }, { body: apiFormattedData })
    },
    assignCard(_, { patientId, number, cvv, trackingLink }) {
      const payload = {
        user_id: patientId,
        card_number: number,
        cvv,
        should_notify: true,
        tracking_link: trackingLink || '',
      }

      return Vue.$http('patient/card/assignCard', {
        number,
        cvv,
      }, { body: payload })
    },
    async revokeCard(_, { cardUUID }) {
      try {
        await Vue.$http('patient/card/revokeCard', cardUUID)
      } catch (e) {
        console.error('[card-revoke] Error evoking card with UUID: ', cardUUID)
      }
    },
    async setTrackingLink(_, { patientId, trackingLink }) {
      const apiFormatedData = {
        user_id: patientId,
        tracking_link: trackingLink,
      }

      return Vue.$http('patient/profile/updateProfile', { patientId }, { body: apiFormatedData })
    },
    async fetchConfirmationCode({ commit }, patientId) {
      try {
        const { data } = await Vue.$http('patient/getConfirmationCode', patientId)

        commit('setConfirmationCode', data.last_pin_reset)
      } catch (e) {
        console.error(`[confirmation-code] Error getting confirmation code for patient ${patientId}.`)
      }
    },
    async updatePatient(_, body) {
      const { patientId } = body

      if (!patientId) {
        return Promise.reject(new Error('Missing patientId'))
      }

      try {
        await Vue.$http('patient/profile/updateProfile', { patientId }, { body })

        return Promise.resolve(true)
      } catch (e) {
        console.error('Error submiting patient data', e)

        return Promise.reject(e)
      }
    },
    async updatePatientOwner(_, { patientId, ownerId }) {
      if (!patientId || !ownerId) {
        return Promise.reject(new Error('Missing info for changing patient ownership'))
      }

      try {
        await Vue.$http('patient/profile/updateProfile', { patientId }, {
          body: {
            user_id: patientId,
            owner_id: ownerId,
          },
        })

        return Promise.resolve(true)
      } catch (e) {
        console.error('Error submiting patient ownership', e)

        return Promise.reject(e)
      }
    },
    async removeProfessionalFromPatient({ getters, commit, dispatch }, { patientId, professionalId }) {
      commit('setProfessionalAsBeingRemoved', professionalId)

      const sharedProfessionalIds = []
      const sharedProfessionals = getters.getSharedProfessionals
      const professionalBeingDeleted = getters.getProfessionalsBeingDeleted

      sharedProfessionals.forEach((sharedProfessional) => {
        const sharedProfessionalId = sharedProfessional.user_id

        if (professionalBeingDeleted.indexOf(sharedProfessionalId) === -1) {
          sharedProfessionalIds.push(sharedProfessionalId)
        }
      })

      try {
        await dispatch('updateSharedProfessionals', {
          patientId,
          sharedProfessionalIds,
        })
        commit('removeProfessionalAsBeingRemoved', professionalId)

        return Promise.resolve(true)
      } catch (e) {
        console.error('Error removing shared professional from user', e)

        return Promise.reject(e)
      }
    },
    async updateSharedProfessionals({ rootGetters }, { patientId, sharedProfessionalIds }) {
      const institutionIdToUse = rootGetters['user/currentInstitutionId']

      try {
        const { data } = await Vue.$http('patient/professionals/updateSharedProfessionals', patientId, {
          body: {
            professionals: sharedProfessionalIds,
            institution_id: institutionIdToUse,
          },
        })

        return Promise.resolve(data)
      } catch (error) {
        console.error('Error updating patient professionals ', error)

        return Promise.reject(error)
      }
    },
    async fetchClinicalPatientMetrics({ commit }, programUUID) {
      try {
        const { data } = await Vue.$http('patient/profile/getClinicalPatientMetrics', programUUID)
        const newContexts = {
          metrics: {
            adherence: data.compliance,
            performance: data.performance,
            satisfaction: data.satisfaction,
          },
          counters: {
            first_training_date: parseInt(data.started_at, 10),
            count_sessions_with_adherence: data.count_sessions,
          },
        }

        commit('setPatientContext', newContexts)
      } catch (error) {
        console.error('Error fetching clinical patient metrics.', error)
      }
    },
    async fetchMoveMemberHeaderData({ commit }, memberAccountId) {
      try {
        const { data } = await Vue.$http('membersMove/members/getHeaderData', memberAccountId)

        commit('setPatientContext', {
          moveHeader: data,
        })
      } catch (error) {
        console.error('Error fetching move patient header data.', error)
      }
    },
    setPatientPropertyStateByProgram({ dispatch }, { program, state }) {
      if (program && Object.keys(program).length) {
        const stateObjFromProgram = {
          current_state:  convertProgramToCurrentState(program),
          remaining_days_in_on_hold: state.current_state?.patient_state?.remaining_days_in_on_hold,
          possible_transitions: program.possible_transitions,
          has_pending_exclusion_request: state.has_pending_exclusion_request || false,
          start_date_on_hold: state?.state_transition_schedule?.schedule_to || null,
          schedule_for_n_days: state?.state_transition_schedule?.schedule_for_n_days || 0,
        }

        dispatch('setPatientPropertyState', stateObjFromProgram)
      } else {
        dispatch('setPatientPropertyState', state)
      }
    },
    setPatientPropertyState({ commit, getters }, state) {
      const { unit: memberUnit } = getters.getMemberBasics

      const payload = state && Object.keys(state).length
        ? makeSetStatePayload(state, memberUnit)
        : defaultPatientStateStructure.state()

      commit('updatePatient', { section: 'state', payload })
    },
    setPatientPropertyOnboardingConfigs({ commit }, onboardingConfigs) {
      if (!onboardingConfigs || !Object.keys(onboardingConfigs).length) return
      commit('setOnboardingConfigs', onboardingConfigs)
    },
    /* TODO: CONFIRM THAT NO FURTHER ENROLLMENT INFO IS NEEDED AND REFACTOR THIS ACTION TO HANDLE JUST REFERRALS DATA,
    AS ONBOARDING DATA IS NOW FETCHED AND SET IN `fetchPatientProfile` ACTION */
    setPatientPropertyEnrollment({ commit }, { enrollment, referrals }) {
      if (!enrollment || !Object.keys(enrollment).length) return
      const onboardingInfo = enrollment.payload
      const careCoordinator = onboardingInfo.care_coordinator

      if (careCoordinator && careCoordinator.filled_by === 'care_coordinator') {
        commit('setPatientCareCoordinatorProfile', careCoordinator.profile)
        commit('setPatientCareCoordinatorQuestions', careCoordinator.additional_patient_questions)
      }
      commit('setPatientServiceType', onboardingInfo.service_type)
      commit('setGuardianInfo', onboardingInfo.guardian)
      commit('setPatientReferrals', referrals)
    },
    setPatientPropertyConfirmationCodes({ commit }, confirmationCodes) {
      if (!confirmationCodes || !Object.keys(confirmationCodes).length) return
      commit('setConfirmationCode', confirmationCodes.last_pin_reset)
    },
    setMemberPTs({ commit }, { institutionId, regulatedPTs, activePTs }) {
      if (regulatedPTs && Object.keys(regulatedPTs).length) {
        commit('setStateRegulatedTherapists', { institutionId, stateTherapists: regulatedPTs })
      }

      if (activePTs && Object.keys(activePTs).length) {
        commit('setActiveTherapists', activePTs)
      }
    },
    async getGeoConfigs({ commit }, patientId) {
      try {
        const { data } = await Vue.$http('patient/geoConfigs', patientId)

        const requiresPTMemberRelationship = data.data.find((item) => item.key === 'requires_pt_member_relationship').value

        commit('setRequiredPTMemberRelationship', requiresPTMemberRelationship)

      } catch (error) {
        console.error(`Error while fetching patient ${patientId} geo-configs`, error)
      }
    },
  },
}
